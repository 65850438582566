<template>
  <div id="about">

    <div class="hidden-sm-and-down">
      <PageHeader/>
    </div>
    <div class="hidden-md-and-up">
      <m-top/>
    </div>

    <div class="hidden-xs-only">
      <el-row type="flex" justify="center">
        <el-col :md="20" :lg="18" :xl="15">
          <div style="width: 100%; max-width: 1284px; margin: 0 auto;">
            <div class="clinic-info">

              <!-- <div class="title">ごあいさつ</div>
              <div class="introduction">
                <div>
                  　院長の太田惠一朗です。現在考えられるベストの治療方針をお示しし、治療にあたります。 どんな時も前向きに、共に歩みましょう。 私は今まで長年にわたり、消化器腫瘍外科医（がんの専門医）として、胃がん、食道がん、大腸がんなど、特に進行した患者さんの外科治療と薬物療法に携わってまいりました。 そうした中で、支持療法を含む早期からの緩和医療・ケアや栄養治療の重要性を説きながら、多職種によるチーム医療を実践してきました。 これからは、その経験と知識を活かしながら、地域にあって、広く消化器疾患を有する方々の診療に当たるつもりでいます。 もちろん、必要に応じて、自ら外科治療に参加することも可能です。<br />
                  　情報があふれる昨今、誤った判断に振り回されたり、不安を増長されたりしている患者さんやご家族に、現在考えられるベストの治療方針を客観的に示したいと思います。 あなたを慰め、あなたの苦痛を和らげ、あなたの病を癒すことに尽くします。 共に歩みましょう。 どんな時も、常に前向き思考（志向）で行きましょう。 どうぞお気軽にご相談ください
                </div>
              </div> -->
              <div class="title">ごあいさつ</div>
              <div class="doctor_greeting">
                <div class="side-text">
                  <div class="greeting">
                    当院は令和3（2021）年10月に開業しました。一般内科として適切な医療を受けるための最初の窓口としての役割を担うため、地域の皆様の“かかりつけ医”となれるよう、親身で丁寧な初期診療を心がけております。中央区築地という地域特性上、様々な背景をお持ちの方が受診されます。患者様個々の背景に応じた治療対応を提供できるように努力しております。診察時の疑問や不安なことだけでなく、どんな些細なことでも構いませんのでお気軽にご相談ください。
                  </div>
                </div>
                
              </div>

              <div class="title">理事長の紹介</div>
              <div class="doctor">
                <div class="photo">
                  <div>
                    <img src="../assets/about/img_takashi.png" alt="">
                  </div>
                  <div class="position">理事長</div>
                  <div class="divider"></div>
                  <div class="name">神垣 隆</div>
                  <div class="name-en">Takashi Kamigaki</div>
                  <!-- <div class="profile-detail">
                    <div>専門分野</div>
                    <div>消化器腫瘍外科学（胃癌、食道癌、大腸癌など）</div>
                    <div>学位</div>
                    <div>医学博士（日本大学第5820号）</div>
                    <div>胃癌におけるCisplatinの腹腔内投与時の薬物動態に関する検討</div>
                  </div> -->
                </div>
                <div class="profile">
                  <div class="experience">略　歴</div>
                  <div class="detail ll">
                    <div>1995年</div>
                    <div>神戸大学大学院医学研究科修了。<br>その後、国立神戸病院 外科、神戸大学医学部附属病院 第一外科などを経て</div>
                    <div>2010年</div>
                    <div>瀬田クリニック大阪クリニック院長</div>
                    <div>2012年</div>
                    <div>医療法人社団滉志会瀬田クリニックグループ<br>臨床研究センター長(現任)</div>
                    <div>2018年</div>
                    <div>順天堂大学 次世代細胞・免疫治療学講座 特任教授</div>
                    <div>2023年</div>
                    <div>順天堂大学 健康総合科学先端研究機構 次世代細胞 免疫治療学プロジェクト 特任教授(現任)</div>
                    <div>2023年</div>
                    <div>医療法人社団 天裕会 理事長就任</div>
                  
                  </div>
                  <div class="experience">専門分野</div>
                  <div class="sennmonn">
                    <div>消化器がん、特に肝胆膵外科領域を専門としてがん治療を長年担っています。近年分子生物学や免疫学が飛躍的に発展した事に伴い、がん標準的治療と先端的治療の併用治療の研究開発及び治療を専門に実施しています。</div>
                  </div>
                    <!-- <div class="society">役　職</div>
                  <div class="society-detail">
                    <div>日本胃癌学会特別会員</div>
                    <div>日本外科学会指導医、専門医、認定医</div>
                    <div>日本消化器外科学会指導医、専門医、認定医</div>
                    <div>日本臨床外科学会特別会員</div>
                    <div>消化器がん外科治療認定医</div>
                    <div>日本外科系連合学会フェロー会員、評議員</div>
                    <div>日本癌治療学会臨床試験登録医</div>
                    <div>日本がん治療認定医機構認定医、暫定教育医</div>
                    <div>日本リンパ学会理事、評議員</div>
                    <div>日本緩和医療学会、暫定指導医</div>
                    <div>日本死の臨床研究会世話人</div>
                    <div>癌とリンパ節研究会幹事</div>
                    <div>CART研究会代表世話人</div>
                    <div>外科漢方フォーラム名誉会員</div>
                    <div>手術手技研究会特別会員</div>
                    <div>日本音楽医療研究会世話人</div>
                    <div>胃外科・術後障害研究会特別会員</div>
                  </div> -->
                </div>
              </div>

              <div class="title">院長の紹介</div>
              <div class="doctor">
                <div class="photo">
                  <div>
                    <!-- <img src="../assets/about/img_ota.png" alt=""> -->
                  </div>
                  <div class="position">院長</div>
                  <div class="divider"></div>
                  <div class="name">中澤 英治</div>
                  <div class="name-en">Eiji Nakazawa</div>
                  <!-- <div class="profile-detail">
                    <div>専門分野</div>
                    <div>消化器腫瘍外科学（胃癌、食道癌、大腸癌など）</div>
                    <div>学位</div>
                    <div>医学博士（日本大学第5820号）</div>
                    <div>胃癌におけるCisplatinの腹腔内投与時の薬物動態に関する検討</div>
                  </div> -->
                </div>
                <div class="profile">
                  <div class="experience">略　歴</div>
                  <div class="detail ll">
                    <div>1973年 3月</div>
                    <div>日本医科大学卒業</div>
                    <div>1974年 1月</div>
                    <div>日本医科大学放射線科入局</div>
                    <div>1981年 4月</div>
                    <div>中澤病院開業（広島市）</div>
                    <div>2021年 5月</div>
                    <div>中澤病院閉院</div>
                    <div>2022年 6月</div>
                    <div>のぞみクリニック築地院長</div>
                    <!-- <div>1978年 3月</div>
                    <div>鹿児島大学医学部卒業</div>
                    <div>1978年 4月</div>
                    <div>虎の門病院外科病棟医</div>
                    <div>1982年 6月</div>
                    <div>国立がんセンターレジデント（外科）</div>
                    <div>1985年 6月</div>
                    <div>（財）癌研究会附属病院外科医員</div>
                    <div>1999年 4月</div>
                    <div>同上医長昇任</div>
                    <div>2003年 6月</div>
                    <div>筑波大学大学院人間総合科学研究科臨床医学系外科講師</div>
                    <div>2006年 1月</div>
                    <div>国際医療福祉大学三田病院外科・消化器センター教授</div>
                    <div>2012年 4月</div>
                    <div>湘南鎌倉総合病院副院長、オンコロジーセンター長</div>
                    <div>2013年 10月</div>
                    <div>聖路加国際病院消化器センター長、消化器・一般外科部長</div>
                    <div>2015年 12月</div>
                    <div>日本医科大学消化器外科教授</div>
                    <div>2019年 4月</div>
                    <div>TKC東京クリニック院長</div>
                    <div>2020年 10月</div>
                    <div>医療法人社団天裕会理事長</div>
                    <div>2021年 1月</div>
                    <div>のぞみクリニック学芸大院長</div>
                    <div>2021年 10月</div>
                    <div>のぞみクリニック築地院長</div> -->
                  </div>
                  <!-- <div class="society">役　職</div>
                  <div class="society-detail">
                    <div>日本胃癌学会特別会員</div>
                    <div>日本外科学会指導医、専門医、認定医</div>
                    <div>日本消化器外科学会指導医、専門医、認定医</div>
                    <div>日本臨床外科学会特別会員</div>
                    <div>消化器がん外科治療認定医</div>
                    <div>日本外科系連合学会フェロー会員、評議員</div>
                    <div>日本癌治療学会臨床試験登録医</div>
                    <div>日本がん治療認定医機構認定医、暫定教育医</div>
                    <div>日本リンパ学会理事、評議員</div>
                    <div>日本緩和医療学会、暫定指導医</div>
                    <div>日本死の臨床研究会世話人</div>
                    <div>癌とリンパ節研究会幹事</div>
                    <div>CART研究会代表世話人</div>
                    <div>外科漢方フォーラム名誉会員</div>
                    <div>手術手技研究会特別会員</div>
                    <div>日本音楽医療研究会世話人</div>
                    <div>胃外科・術後障害研究会特別会員</div>
                  </div> -->
                </div>
              </div>

              

              <div class="facility">
                <div class="title">施設・設備の紹介</div>
                  <ul class="introduce-wrap">
                    <li class="introduce">
                      <div class="img"><img src="@/assets/about/img_hope01.png" alt="受付" height="400"></div>
                      <div class="text">受付</div>
                    </li>
                    <li class="introduce">
                      <div class="img"><img src="@/assets/about/img_hope02.png" alt="診察室" height="400"></div>
                      <div class="text">診察室</div>
                    </li>
                  </ul>
                  <ul class="introduce-wrap">
                    <li class="introduce">
                      <div class="img"><img src="@/assets/about/img_hope03.png" alt="採血室" height="400"></div>
                      <div class="text">採血室</div>
                    </li>
                    <li class="introduce">
                      <div class="img"><img src="@/assets/about/img_hope04.png" alt="待合室" height="400"></div>
                      <div class="text">待合室</div>
                    </li>
                  </ul>
              </div>

            </div>
          </div>
        </el-col>
      </el-row>

      <home-access/>

      <PageFooter :show-list="false"/>

    </div>

    <div class="hidden-sm-and-up">
      <!-- <div>
        <div class="m-tittle">ごあいさつ</div>
        <div class="m-detail">
          <div>院長の太田惠一朗です。現在考えられるベストの治療方針をお示しし、治療にあたります。 どんな時も前向きに、共に歩みましょう。 私は今まで長年にわたり、消化器腫瘍外科医（がんの専門医）として、胃がん、食道がん、大腸がんなど、特に進行した患者さんの外科治療と薬物療法に携わってまいりました。 そうした中で、支持療法を含む早期からの緩和医療・ケアや栄養治療の重要性を説きながら、多職種によるチーム医療を実践してきました。 これからは、その経験と知識を活かしながら、地域にあって、広く消化器疾患を有する方々の診療に当たるつもりでいます。 もちろん、必要に応じて、自ら外科治療に参加することも可能です。</div>
          <div>情報があふれる昨今、誤った判断に振り回されたり、不安を増長されたりしている患者さんやご家族に、現在考えられるベストの治療方針を客観的に示したいと思います。 あなたを慰め、あなたの苦痛を和らげ、あなたの病を癒すことに尽くします。 共に歩みましょう。 どんな時も、常に前向き思考（志向）で行きましょう。 どうぞお気軽にご相談ください</div>
        </div>
      </div> -->
      <div>
        <div class="m-tittle">当院について</div>
        <div class="m-doctor">
            <div class="m-subTittle">ごあいさつ</div>
              <div class="m_greeting">
                当院は令和３（２０２１）年10月に開業しました。一般内科として適切な医療を受けるための最初の窓口としての役割を担うため、地域の皆様の“かかりつけ医“となれるよう、親身で丁寧な初期診療を心がけております。中央区築地という地域特性上、様々な背景をお持ちの方が受診されます。患者様個々の背景に応じて治療対応を提供できるように努力しております。診察時の疑問や不安なことだけでなく、どんな些細なことでも構いませんのでお気軽にご相談ください。
              </div> 
        </div>
      </div>

      <div>
        <div class="m-tittle">理事長の紹介</div>
        <div class="m-doctor">
          <div class="m-photo">
            <img src="../assets/about/img_takashi.png" alt=""> 
            <div class="position">理事長</div>
            <div class="divider"></div>
            <div class="name">神垣 隆</div>
            <div class="name-en">Takashi Kamigaki</div>
          </div>
          <div>
            <div class="m-subTittle">略　歴</div>
            <div class="sub-experience ll">
              <div>1995年</div>
              <div>神戸大学大学院医学研究科修了。<br>その後、国立神戸病院 外科、神戸大学医学部附属病院 第一外科などを経て</div>
              <div>2010年</div>
              <div>瀬田クリニック大阪クリニック院長</div>
              <div>2012年</div>
              <div>医療法人社団滉志会瀬田クリニックグループ<br>臨床研究センター長(現任)</div>
              <div>2018年</div>
              <div>順天堂大学 次世代細胞・免疫治療学講座 特任教授</div>
              <div>2023年</div>
              <div>順天堂大学 健康総合科学先端研究機構 次世代細胞 免疫治療学プロジェクト 特任教授(現任)</div>
              <div>2023年</div>
              <div>医療法人社団 天裕会 理事長就任</div>
            </div>
            <div class="m-subTittle">専門分野</div>
              <div class="sennmonn">
              <div>消化器がん、特に肝胆膵外科領域を専門としてがん治療を長年担っています。近年分子生物学や免疫学が飛躍的に発展した事に伴い、がん標準的治療と先端的治療の併用治療の研究開発及び治療を専門に実施しています。</div>
            </div>
          </div>
        </div>
        <div class="m-tittle">院長の紹介</div>
        <div class="m-doctor">
          <div class="m-photo">
            <!-- <img src="../assets/about/img_ota.png" alt=""> -->
            <div class="position">院長</div>
            <div class="divider"></div>
            <div class="name">中澤 英治</div>
            <div class="name-en">Eiji Nakazawa</div>
            <!-- <div class="profile-detail">
              <div>専門分野</div>
              <div>消化器腫瘍外科学（胃癌、食道癌、大腸癌など）</div>
              <div>学位</div>
              <div>医学博士（日本大学第5820号）</div>
              <div>胃癌におけるCisplatinの腹腔内投与時の薬物動態に関する検討</div>
            </div> -->
          </div>           
          <div>
            <div class="m-subTittle">略　歴</div>
            <div class="sub-experience ll">
              <div>1973年 3月</div>
              <div>日本医科大学卒業</div>
              <div>1974年 1月</div>
              <div>日本医科大学放射線科入局</div>
              <div>1981年 4月</div>
              <div>中澤病院開業（広島市）</div>
              <div>2021年 5月</div>
              <div>中澤病院閉院</div>
              <div>2022年 6月</div>
              <div>のぞみクリニック築地院長</div>
              <!-- <div>1978年03月</div>
              <div>鹿児島大学医学部卒業</div>
              <div>1978年04月</div>
              <div>虎の門病院外科病棟医</div>
              <div>1982年06月</div>
              <div>国立がんセンターレジデント（外科）</div>
              <div>1985年06月</div>
              <div>（財）癌研究会附属病院外科医員</div>
              <div>1999年04月</div>
              <div>同上医長昇任</div>
              <div>2003年06月</div>
              <div>筑波大学大学院人間総合科学研究科臨床医学系外科講師</div>
              <div>2006年01月</div>
              <div>国際医療福祉大学三田病院外科・消化器センター教授</div>
              <div>2012年04月</div>
              <div>湘南鎌倉総合病院副院長、オンコロジーセンター長</div>
              <div>2013年10月</div>
              <div>聖路加国際病院消化器センター長、消化器・一般外科部長</div>
              <div>2015年12月</div>
              <div>日本医科大学消化器外科教授</div>
              <div>2019年04月</div>
              <div>TKC東京クリニック院長</div>
              <div>2020年10月</div>
              <div>医療法人社団天裕会理事長</div>
              <div>2021年01月</div>
              <div>のぞみクリニック学芸大院長</div>
              <div>2021年10月</div>
              <div>のぞみクリニック築地院長</div> -->
            </div>
          </div>
        </div>

      </div>

      <div>
        <div class="m-tittle">施設・設備の紹介</div>
        <van-swipe :loop="true" :width="pageWidth" autoplay="5000" :duration="1000">
          <van-swipe-item v-for="item in cardList"v-bind:key="item.id">
            <div class="card">
              <div class="pic"><el-image :src="require(`@/assets/about/${item.picUrl}`)"/></div>
              <div class="sub-name">{{item.name}}</div>
              <div class="detail">
                <div v-for="detail in item.details">{{detail}}</div>
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>

        <m-access/>
        <m-footer/>
      </div>

  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
import HomeAccess from "@/components/home/HomeAccess";
import MTop from "../components/m/MTop";
import MIntroduce from "../components/m/MIntroduce";
import MAccess from "../components/m/MAccess";
import MFooter from "../components/m/MFooter";
export default {
  name: "About",
  components: {MFooter, MAccess, MIntroduce, MTop, HomeAccess, PageFooter, PageHeader},
  data() {
    return {
      cardList: [
        { 'picUrl': 'img_hope01.png', 'name': '受付', 'details': [''] },
        { 'picUrl': 'img_hope02.png', 'name': '診察室', 'details': [] },
        { 'picUrl': 'img_hope03.png', 'name': '採血室', 'details': [] },
        { 'picUrl': 'img_hope04.png', 'name': '待機室', 'details': [] },
      ],
      pageWidth:0,
    }
  },
  methods: {
    getFooterTop() {
      // let pageHeight = document.documentElement.offsetHeight;//页面高度
      // let topper = document.getElementById('about').children[0]
      // let topperHeight = topper.offsetHeight;//除了footer的，上半部分组件的高度
      //
      // let diffHeight = pageHeight - topperHeight
      // if (diffHeight < 0) {
      //   return;
      // }
      // let footerHeight = document.getElementById('footer').scrollHeight + 10
      // topper.style.marginBottom = (diffHeight-footerHeight) + 'px';
      this.pageWidth = document.documentElement.offsetWidth;
    }
  },
  beforeMount() {
    // 刚加载页面时，获取高度
    this.getFooterTop()
  },
  mounted() {
    // 调整页面高度时，获取高度
    window.onresize = () => {
      this.getFooterTop()
    }
  }
}
</script>

<style scoped>

.clinic-info {
  margin-top: 5rem;
}
.title {
  text-align: center;
  margin-top: 5rem;
  font-size: 24px;
  font-weight: bold;
  color: #333;
  letter-spacing: 5px;
}

.introduction {
  margin-top: 30px;
  padding: 60px;
  font-size: 14px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: #FFFFFF;
  color: #333;
  line-height: 36px;
}

.doctor {
  display: flex;
  margin: 2rem 0;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}


.doctor_greeting {
  display: flex;
  margin: 2rem 0;
}
.doctor .photo, .doctor .profile {
  width: 50%;
  padding: 3rem;

  display: flex;
  flex-direction: column;
  /*justify-content: space-between;*/
}
.doctor .photo {
  background-color: #ffffff;
  border-radius: 10px 0 0 10px;
  align-items: center;
}
.doctor .photo>div:nth-child(1) {
  display: inline-block;
  position: relative;
  width: 406px;
  /* height: 250px; */
}
.doctor .photo>div:nth-child(1) img {
  width: 100%;
  height: 100%;
  border-radius: 40px;
}
.doctor .photo>div:nth-child(2) {
  /* margin-top: 1rem; */
  margin-top: 2.5rem;
}
.doctor .photo .position {
  font-size: 20px;
  color: #333;
  letter-spacing: 4px;
}
.doctor .photo .divider {
  height: 0;
  width: 60%;
  margin: 10px;
  border: 2px solid #1796C4;
}
.doctor .photo .name {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  letter-spacing: 5px;
}
.doctor .photo .name-en {
  font-size: 16px;
  letter-spacing: 6px;
  color: #707070;
  margin-top: 6px;
}
.doctor .profile {
  background-color: #EDF7FC;
  border-radius: 0 10px 10px 0;
}
.doctor .profile .sub-title {
  font-weight: bold;
  font-size: 20px;
  color: #333;
  letter-spacing: 4px;
}
.doctor .profile-detail {
  margin-top: 40px;
  font-size: 14px;
  color: #333;
  line-height: 32px;
  text-align: left;
}
.doctor .profile .experience {
  color: #333;
  letter-spacing: 4px;
  font-size: 20px;
  font-weight: bold;
  margin-top: 1rem;
}
.doctor .profile .detail {
  margin-top: 1rem;
  color: #333;
  line-height: 20px;
  font-size: 14px;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-row-gap: 10px;
}
.doctor .society {
  font-weight: bold;
  font-size: 20px;
  color: #333;
  margin-top: 2rem;
  letter-spacing: 4px;
}
.doctor .society-detail {
  margin-top: 20px;
  font-size: 14px;
  color: #333;
  line-height: 28px;
}

/* 施設の紹介 */
.facility {
  margin: 60px 40px;
}
.facility .title {
  color: #535353;
  font-size: 24px;
  font-weight: bold;
  text-align: left;
}
.introduce-wrap {
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
}
.introduce .img img {
  width: 100%;
  height: 100%;
}
.introduce .text {
  color: #535353;
  font-size: 20px;
  text-align: center;
  letter-spacing: 4px;
  margin-top: 20px;
}

/*手机端*/
.m-tittle {
  text-align: center;
  margin: 2rem 0;
  font-size: 24px;
  font-weight: bold;
  color: #333;
  letter-spacing: 5px;
}
.m-detail {
  background-color: #FFFFFF;
  text-indent: 1rem;
  line-height: 25px;
  padding: 1rem;
  font-size: 16px;
  color: #333;
}
.m-detail div:nth-child(1) {
  margin-bottom: 0.5rem;
}

.m-doctor {
  background-color: #FFFFFF;
  padding: 1rem 2rem;
}
.m-doctor .m-photo>img {
  width: 100%;
  height: 100%;
  border-radius: 30px;
}
.m-photo .position {
  text-align: center;
  margin: 1rem;
  font-size: 20px;
  color: #333;
  letter-spacing: 4px;
}
.m-photo .divider {
  height: 0;
  width: 80%;
  margin: 10px auto;
  border: 2px solid #1796C4;
}
.m-photo .name {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  letter-spacing: 5px;
  text-align: center;
}
.m-photo .name-en {
  font-size: 16px;
  letter-spacing: 6px;
  color: #707070;
  margin-top: 6px;
  text-align: center;
}
.m-photo .profile-detail {
  color: #333;
  margin-top: 1rem;
  line-height: 25px;
}
.m-photo .profile-detail>div:nth-child(1),
.m-photo .profile-detail>div:nth-child(3) {
  font-weight: bold;
}
.m-photo .profile-detail>div:nth-child(2) {
  margin-bottom: 1rem;
}
.m-subTittle {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #333;
  letter-spacing: 4px;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.m-doctor .sub-experience {
  font-size: 14px;
  line-height: 20px;
  color: #333;

  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-row-gap: 10px;
}
.m-doctor .society-detail {
  font-size: 14px;
  line-height: 30px;
  color: #333;
}

.m_greeting{
  line-height: 30px;
}

.greeting{
  line-height: 40px;
}

.card {
  background-color: #fff;
  width: 90%;
  height: 300px;
  margin: 0 auto 1.5rem;

  display: flex;
  flex-direction: column;
  align-items: center;
}
.card .pic {
  width: 100%;
}
.card .pic img {
  width: 100%;
  height: 100%;
}
.card .sub-name {
  color: #333;
  font-size: 14px;
  letter-spacing: 3px;
  margin: 1rem 0;
}
.card .detail>div {
  color: #333;
  font-size: 12px;
  line-height: 18px;
}
.ll div{
  line-height: 26px;
}
.side-text{
  height: fit-content;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: auto;
}

.sennmonn{
  margin-top: 1rem;
  color: #333;
  line-height: 26px;
  font-size: 14px;
}

</style>
